// import { useState, useEffect } from 'react';
// import {projectAuth, projectFirestore} from "../firebase/config";
// import { useAuthContext } from "./useAuthContext";

// export const useLogout = () => {
//     const [isCancelled, setIsCancelled] = useState(false);
//     const [error, setError] = useState(null);
//     const [isPending, setIsPending] = useState(false);
//     const { dispatch, user } = useAuthContext()

//     const logout = async () => {
//         setError(null)
//         setIsPending(true)

//         try{
//             //update online status
//             const { uid } = user
//             await projectFirestore.collection('users').doc(uid).update({online:false})

//             await projectAuth.signOut()

//         //    dispatch logout action
//             dispatch({type: 'LOGOUT'})
//             if (!isCancelled){
//                 setIsPending(false)
//                 setError(null)
//             }

//         } catch(err) {
//             if(!isCancelled){
//                 console.log(err)
//                 setError(err.message)
//                 setIsPending(false)
//             }
//         }
//     }

//     useEffect(() => {
//         return () => setIsCancelled(true)
//     }, []);

//     return {logout, error, isPending}
// };

import { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { projectAuth, projectFirestore } from '../firebase/config';
import { useAuthContext } from './useAuthContext';

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch, user } = useAuthContext();

    const logout = async () => {
        setError(null);
        setIsPending(true);

        try {
            // Update online status
            const { uid } = user;
            const userDocRef = doc(projectFirestore, 'users', uid);
            await updateDoc(userDocRef, { online: false });

            // Sign out the user
            await signOut(projectAuth);

            // Dispatch logout action
            dispatch({ type: 'LOGOUT' });

            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            if (!isCancelled) {
                console.error(err);
                setError(err.message);
                setIsPending(false);
            }
        }
    };

    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { logout, error, isPending };
};