// import {useEffect, useRef, useState} from 'react';
// import {projectFirestore} from "../firebase/config";

// export const useCollection = (collection, query1, queryCompare, query2, orderBy1, direction) => {
//     const [documents, setDocuments] = useState(null);
//     const [error, setError] = useState(null);

//     // if we dont use a ref --> infinite loop in useEffect
//     // the array _query is viewed a different on every function call thus making an endless loop
//     // useRef is a way to get around this
//     // let orderByDue = due

//     const query = useRef(query1, queryCompare, query2).current
//     const orderBy = useRef(orderBy1, direction).current
//     useEffect(() => {
//         let ref = projectFirestore.collection(collection)
//         if (query){
//             ref = ref.where(query1, queryCompare, query2)
//         }
//         if (orderBy){
//             ref = ref.orderBy(orderBy1,direction)
//         }
//         const unsubscribe = ref.onSnapshot((snapshot) => {
//             let results = []
//             // see firestore documentation on snapshot for code explanation
//             snapshot.docs.forEach(doc => {
//                 results.push({ ...doc.data(), id:doc.id })
//             })

//         //     update state
//             setDocuments(results)
//             setError(null)
//         }, (error) => {
//             console.log(error)
//             setError("could not fetch data")
//         })
//     //    unsubscribe on unmount
//         return () => unsubscribe()

//     }, [collection])
//     return {documents, error}
// };

import { useEffect, useRef, useState } from 'react';
import { collection, query as firestoreQuery, where, orderBy, onSnapshot } from 'firebase/firestore';
import { projectFirestore } from '../firebase/config';

export const useCollection = (collectionName, query1, queryCompare, query2, orderBy1, direction) => {
    const [documents, setDocuments] = useState(null);
    const [error, setError] = useState(null);

    // Use refs to avoid infinite loops in useEffect
    const queryRef = useRef([query1, queryCompare, query2]).current;
    const orderByRef = useRef([orderBy1, direction]).current;

    useEffect(() => {
        let ref = collection(projectFirestore, collectionName); // Get the collection reference

        // Build the Firestore query
        let q = ref;
        if (queryRef[0] && queryRef[1] && queryRef[2]) {
            q = firestoreQuery(q, where(queryRef[0], queryRef[1], queryRef[2]));
        }
        if (orderByRef[0] && orderByRef[1]) {
            q = firestoreQuery(q, orderBy(orderByRef[0], orderByRef[1]));
        }

        // Subscribe to the query
        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                let results = [];
                snapshot.docs.forEach((doc) => {
                    results.push({ ...doc.data(), id: doc.id });
                });

                // Update state
                setDocuments(results);
                setError(null);
            },
            (error) => {
                console.error(error);
                setError('Could not fetch data');
            }
        );

        // Unsubscribe on unmount
        return () => unsubscribe();
    }, [collectionName, queryRef, orderByRef]);

    return { documents, error };
};