// import { useState, useEffect } from 'react';
// import {projectFirestore} from "../firebase/config";

// export const useDocument = (collection, id) => {
//     const [document, setDocument] = useState(null);
//     const [error, setError] = useState(null);
// //    realtime data for document
//     useEffect(() => {
//         const docRef = projectFirestore.collection(collection).doc(id)

//         // firestore function, onSnapshot tell us when the document changes
//         // this also returns an unsubscribe function
//         const unsubscribe = docRef.onSnapshot((snapshot) => {
//             if (snapshot.data()){
//                 //gives us the updated snapshot and all the old data, spread out and including the id of document
//                 setDocument({...snapshot.data(), id: snapshot.id})
//                 setError(null)
//             }
//             else{
//                 setError("No such document exists")
//             }
//         }, (err) => {
//             console.log(err.message)
//             setError("Failed to get document")
//         })

//         return () => unsubscribe()

//     }, [collection, id]);
//     return{document, error}
// };

import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { projectFirestore } from '../firebase/config';

export const useDocument = (collectionName, id) => {
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(null);

    // Realtime data for a document
    useEffect(() => {
        const docRef = doc(projectFirestore, collectionName, id); // Get the document reference

        // Firestore function: onSnapshot listens for document changes
        const unsubscribe = onSnapshot(
            docRef,
            (snapshot) => {
                if (snapshot.exists()) {
                    // Spread the document data and include the document ID
                    setDocument({ ...snapshot.data(), id: snapshot.id });
                    setError(null);
                } else {
                    setError('No such document exists');
                }
            },
            (err) => {
                console.error(err.message);
                setError('Failed to get document');
            }
        );

        // Cleanup: unsubscribe on unmount
        return () => unsubscribe();
    }, [collectionName, id]);

    return { document, error };
};