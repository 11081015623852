import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
// import useAuthContext from 'hooks/useAuthContext'

// import Signup from "./pages/signup/signup";
import Navbar from "./components/navbar";
import Dashboard from "./pages/dashboard/Dashboard";
import Create from "./pages/create/Create";
import Project from "./pages/project/Project";
import Login from "./pages/login/Login";
import Sidebar from "./components/Sidebar";
import { useAuthContext } from "./hooks/useAuthContext";
import StoredCompleted from "./pages/storedCompleted/StoredCompleted";
import StoredCompletedTable from "./pages/storedCompleted/StoredCompletedTable";
import ProjectFutureAssignment from "./pages/project/ProjectFutureAssignment";

function App() {
  const { user, authIsReady } = useAuthContext();
    
    if (!authIsReady) return <div>Loading...</div>;
    
  return (
    <BrowserRouter> {/* ✅ Wrap everything in BrowserRouter */}
      <div className="App">
        {user && <Sidebar />}
        <div className="container">
          <Navbar />
          <Routes>
            <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="/create" element={user ? <Create /> : <Navigate to="/login" />} />
            <Route path="/projects/:id" element={user ? <Project /> : <Navigate to="/login" />} />
            <Route path="/completed" element={user ? <StoredCompleted /> : <Navigate to="/login" />} />
            <Route path="/not-published" element={user ? <ProjectFutureAssignment /> : <Navigate to="/login" />} />
            <Route path="/completed/gridView" element={user ? <StoredCompletedTable /> : <Navigate to="/login" />} />
            <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
            <Route path="/signup" element={user ? <Navigate to="/" /> : <Login />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}
export default App;